<template>
  <div class="header displayRow">
    <div>
      <img class="header-img" :src="logo" alt="千电照明" />
    </div>
    <div class="displayRow">
      <div class="search">
        <el-input placeholder="请输入内容" v-model="searchVal">
           <template slot="append">
             <div class="displayRow">
               <el-upload
                class="avatar-uploader"
                action="http://qiandian8888.com/soutu-server/imagesearch/product/search"
                :show-file-list="false"
                 :http-request="uploadPic"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-camera"></i>
              </el-upload>
           
                 <el-button type="primary" class="searchBtn" icon="el-icon-search" @click="ToSearch"></el-button>
             </div>
           </template>
          
        </el-input>
      </div>
      <div  class="avatarCon" @click="toUserInfo"  v-if="userInfo.token" >
        <el-avatar :src="userInfo.avatar ? userInfo.avatar : defaultHead"></el-avatar>
        <span class="nickName fontBold">{{userInfo.realname}}</span>
      </div>
        <span class="iconfont icon-denglu" v-else @click="toLogin"></span> 
        
      <el-badge :value="shopCarLen" class="item">
        <span class="iconfont icon-gouwuche" @click="toShopCar"></span>
      </el-badge>
      
    </div>
 
  </div>
</template>

<script>
import {imgSearch} from '@/assets/js/api.js'
import Bus from "@/assets/js/bus.js";
import {nanoid} from 'nanoid';
export default {
  data() {
    return {
      searchVal: "",
      logo: require("@/assets/image/logo.jpg"),
      imageUrl: '',
      dialogVisible:false,
      shopCarLen:0,
      avatarImg:'',
      nickName:'',
      defaultHead:require("@/assets/image/head.png"),
      userInfo:{},
    };
  },
  methods: {
    uploadPic(item){
      const loading = this.$loading({
          lock: true,
          text: '图片上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = new FormData();
        data.append('file',item.file); 
        imgSearch(data).then(res =>{
          if(res.data.code === 200){
            let imgSearchArr = JSON.stringify(res.data.result);
            localStorage.setItem('imgSearchArr',imgSearchArr);
              loading.close();
               this.$router.push({
                  path: "/GoodsData",
                  query: {
                    v: Math.random(),
                  },
                });
          }
        })
    },
     
        beforeAvatarUpload(file) {
        const isLt4M = file.size / 1024 / 1024 < 4;
        if (!isLt4M) {
           this.imgCompress(file, { quality: 0.2 }, "file");
        }
      },

       //图片压缩
    imgCompress(path, obj, statu) {
      let _this = this; //这里的this 是把vue的实例对象指向改变为_this
      var img = new Image();
      img.src = path.src;
      img.onload = function() {
        var that = this; //这里的this 是把img的对象指向改变为that

        // 默认按比例压缩

        var w = that.width,
          h = that.height,
          scale = w / h;

        w = obj.width || w;

        h = obj.height || w / scale;

        var quality = 0.7; // 默认图片质量为0.7

        //生成canvas

        var canvas = document.createElement("canvas");

        var ctx = canvas.getContext("2d");

        // 创建属性节点

        var anw = document.createAttribute("width");

        anw.nodeValue = w;

        var anh = document.createAttribute("height");

        anh.nodeValue = h;

        canvas.setAttributeNode(anw);

        canvas.setAttributeNode(anh);

        ctx.drawImage(that, 0, 0, w, h);

        // 图像质量

        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality;
        }

        // quality值越小，所绘制出的图像越模糊

        var base64 = canvas.toDataURL("image/jpeg", quality);

        // 回调函数返回base64的值

        var urlFile = _this.convertBase64UrlToBlob(base64); //这个地方的处理是为了把压缩的base64转化为对象，获得压缩后图片的大小size，方便对压缩后的图片再次进行判断；

        // console.log(urlFile)

        let file = _this.blobToFile(urlFile, path.name);
        _this.file = file;
      };
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    },

        blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();

      theBlob.name = fileName;
      return theBlob;
    },
    
    // 搜索
    ToSearch() {
      localStorage.removeItem('imgSearchArr');
      this.$router.push({
        path: "/GoodsData",
        query: {
          search: this.searchVal,
          v: Math.random(),
        },
      });
    },

    // 去购物车
    toShopCar(){
      this.$router.push('/ShopCar');
    },
    
  // 去登录
    toLogin(){
          let path = this.$route.fullPath;
          let nanoidStr = nanoid();
          localStorage.setItem(nanoidStr,path);
          let url = `https://qiandian8888.com/soutu-server/sys/thirdLogin/render/wechat_open?state=${nanoidStr}`;
          window.location.href = url;
    },

    // 去个人中心
    toUserInfo(){
       this.$router.push('/MyOrder');
    }
  },
  watch:{
  },
  mounted(){
       Bus.$on("e-shopCarLen", (data) => {
        this.shopCarLen = data;
    });
  },
  created(){
    
    // this.shopCar = localStorage.getItem('ShopCarArr') ? JSON.parse(localStorage.getItem('ShopCarArr')) : [];
    // this.shopCarLen = this.shopCar.length;
    this.userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')):{};
  }
};
</script>

<style scoped>
.header {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 28px 0;
  justify-content: space-between;
}
.row-bg {
  align-items: center;
}
.header-img {
  height: 80px;
  margin-right: 50px;
}
.phone {
  margin-left: 40px;
}
.icon-fuwurexian {
  font-size: 35px;
  color: #0388dd;
  margin-right: 10px;
}
.search {
  width: 400px;
}
.icon-denglu {
  color: #0388dd;
  font-size: 30px;
  margin: 0 30px;
  cursor: pointer;
}
.avatarCon{
margin: 0 30px;
cursor: pointer;
}
.icon-gouwuche {
  color: #0388dd;
  font-size: 28px;
  cursor: pointer;
}
.qrcode-img {
  margin-left: 30px;
  width: 80px;
  height: 80px;
}
.searchBtn{
 border-radius: 0 4px 4px 0;
 border:1px solid #DCDFE6;
}
.nickName{
  font-size: 12px;
}
</style>